<template>
  <div>
    <b-modal id="modal-enfermeria" size="lg"
      title="Enfermeria"
      :visible="visible"
      @change="$emit('change', $event)"
    >
      <template #modal-header="{ close }">
        <div class="row">
          <div class="col-8">
            <div class="col-12">
              <strong>Nº HC: 451</strong> Origen: 332/22 (12/01/2022 - Hora: 12:33)
            </div>
            <div class="col-12">
              <strong>Paciente: </strong> MELANY FELICIDAD MACIAS LOZA - Edad: 23 años
            </div>
          </div>
          <div class="col-2">
            <strong>Nº HC: 451</strong>
          </div>
          <div class="col-2 text-right">
            <span class="font-weight-bold align-middle" @click="close()"
              style="cursor: pointer">X</span>
          </div>
        </div>
      </template>
      <div class="row px-3">
        <div class="col-4 pb-3">
          <div class="form-group">
            <label for="talla"><strong>Talla:</strong></label>
            <input type="text" id="talla" class="form-control">
          </div>
          <div class="form-group">
            <label for="talla"><strong>IMC</strong> <span class="font-10">(Indice Mesa Corporal)</span></label>
            <input type="text" id="talla" class="form-control">
          </div>
          <div class="form-group">
            <label for="talla"><strong>FC</strong> <span class="font-10">(Frecuencia Cardiaca)</span></label>
            <input type="text" id="talla" class="form-control">
          </div>
        </div>
        <div class="col-4 pb-3">
          <div class="form-group">
            <label for="talla"><strong>Peso:</strong></label>
            <input type="text" id="talla" class="form-control">
          </div>
          <div class="form-group">
            <label for="talla"><strong>FR</strong> <span class="font-10">(Frecuencia Respiratoria)</span></label>
            <input type="text" id="talla" class="form-control">
          </div>
          <div class="form-group">
            <label for="talla"><strong>SAT 02</strong> <span class="font-10">(Saturación de Oxigeno)</span></label>
            <input type="text" id="talla" class="form-control">
          </div>
        </div>
        <div class="col-4 pb-3">
          <div class="form-group">
            <label for=""><strong>Temperatura:</strong></label>
            <div class="row">
              <div class="col-4 d-flex align-items-center justify-content-end px-0">
                <label for="oral"><strong>Oral:</strong></label>
              </div>
              <div class="col-8">
                <input type="text" id="oral" class="form-control">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for=""><strong>PA</strong> <span class="font-10">(Presión Arterial)</span></label>
            <div class="row pb-2">
              <div class="col-4 d-flex align-items-center justify-content-end px-0">
                <label for="sistolica"><strong>Sistolica:</strong></label>
              </div>
              <div class="col-8">
                <input type="text" id="sistolica" class="form-control">
              </div>
            </div>
            <div class="row">
              <div class="col-4 d-flex align-items-center justify-content-end px-0">
                <label for="diastolica"><strong>Diastolica:</strong></label>
              </div>
              <div class="col-8">
                <input type="text" id="diastolica" class="form-control">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row p-4">
        <div class="col-6 bg-gray p-3">
          <div><h4><strong>Dra. Ablail Lopez</strong></h4></div>
          <div class="font-10"><strong>Pediatra</strong></div>
          <div class="pt-3">Turno 1 de: 08:00 a 15:00</div>
        </div>
        <div class="col-6 bg-custom-green p-3 text-center">
          <div><h4><strong>Consultorio:</strong> 0000000000</h4></div>
          <div class="font-10 tetxt-white"><h4><strong>Libre</strong></h4></div>
        </div>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <button class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
        <button class="btn bg-custom-green" @click="ok()">
          Guardar
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'theModalEnfermeria',
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scope>
.modal-header {
  display:block !important;
}
.font-10 {
  font-size: 10px;
}
.bg-gray {
  background-color: rgb(200,200,200);
}
</style>
