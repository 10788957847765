<template>
  <div>
      <div class="breadcrumb">
        <h1>Asistencia Enfermeria</h1>
      </div>
      <div class="row">
        <div class="col-12 card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-4">
                <!-- <button
                  class="btn th-custom-color px-4 rounded mr-2"
                  @click="irACrearAsistencia"
                >
                  Nuevo
                </button> -->
              </div>
              <div class="col-sm-3 offset-sm-5">
                <div class="text-right">
                  <label class="switch switch-success mr-3">
                    <input type="checkbox" id="filter"
                     :checked="estado"
                     v-model="estado">
                    <span class="slider"></span>
                    <span>{{ labelActivos }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row py-3">
              <div class="col-8">
                <div><strong><h4>Pendientes {{pendientes.length}}</h4></strong></div>
                <div>Ver Cerrados</div>
              </div>
              <div class="col-4 text-right">
                <h4>Atención Enfermeria</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-3 p-3" v-for="(pendiente, index) in pendientes" :key="index">
                <div class="col-12 p-3 bg-gray border-radius">
                  <div class="col-12 p-0">
                    <div class="row m-0 p-0">
                      <div class="col-8 p-0 text-left">
                        <i
                          @click="onClickEditarIcon()"
                          class="nav-icon i-Pen-5 font-weight-bold text-success mr-3"
                          style="cursor: pointer"
                        ></i>
                        <i
                          @click="onClickEliminarIcon()"
                          class="nav-icon i-Close-Window font-weight-bold text-danger"
                          style="cursor: pointer"
                        ></i>
                      </div>
                      <div class="col-4 text-right">
                        <h4><strong>N: {{pendiente.numero}}</strong></h4>
                      </div>
                    </div>
                  </div>
                  <div><h5><strong>{{pendiente.paciente}}</strong></h5></div>
                  <div><strong>{{pendiente.servicio}}</strong></div>
                  <div>{{pendiente.categoria}}</div>
                  <div class="pt-2"><strong>Origen: </strong>{{pendiente.origen}}</div>
                  <div class="row">
                    <div class="col-7"><strong>Fecha: </strong>{{pendiente.fecha}}</div>
                    <div class="col-5"><strong>Hora: </strong>{{pendiente.hora}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <modalEnfermeria v-model="showEnfermeria" />
  </div>
</template>

<script>
import modalEnfermeria from '../components/theModalEnfermeria.vue';

export default {
  name: 'asistenciaIndex',
  components: {
    modalEnfermeria,
  },
  data() {
    return {
      estado: true,
      pendientes: [
        {
          numero: 1,
          paciente: 'Veronica Pascual Valeriano',
          servicio: 'Consulta Medicina General',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
        },
        {
          numero: 2,
          paciente: 'Veronica Pascual Valeriano',
          servicio: 'Consulta Medicina General',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
        },
        {
          numero: 3,
          paciente: 'Veronica Pascual Valeriano',
          servicio: 'Consulta Medicina General',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
        },
        {
          numero: 4,
          paciente: 'Veronica Pascual Valeriano',
          servicio: 'Consulta Medicina General',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
        },
        {
          numero: 5,
          paciente: 'Veronica Pascual Valeriano',
          servicio: 'Consulta Medicina General',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
        },
        {
          numero: 6,
          paciente: 'Veronica Pascual Valeriano',
          servicio: 'Consulta Medicina General',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
        },
        {
          numero: 7,
          paciente: 'Veronica Pascual Valeriano',
          servicio: 'Consulta Medicina General',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
        },
        {
          numero: 8,
          paciente: 'Veronica Pascual Valeriano',
          servicio: 'Consulta Medicina General',
          categoria: 'Medicina General',
          origen: '333/22',
          fecha: '12/12/2013',
          hora: '10:33',
        },
      ],
      showEnfermeria: false,
    };
  },
  computed: {
    labelActivos() {
      if (this.estado) {
        return 'Activos';
      }
      return 'Inactivos';
    },
  },
  methods: {
    onClickEditarIcon() {
      this.showEnfermeria = true;
    },
    onClickEliminarIcon() {

    },
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: rgb(182, 182, 182);
}
.border-radius {
  border-radius: 10px;
}
</style>
